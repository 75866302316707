import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Prodcuts from "../components/products"
import Footer from "../components/footer"
import About from "../components/about"
import Hero from "../components/hero"
import Contact from "../components/contact"
import News from "../components/news"

export default class AboutPage extends React.Component {

  render() {

    return (

      <Layout>

        <Seo title="SPCO" lang="en" />

        <section id="contact" className="wow animate__fadeIn">

          <div className="container">

            <div className="row justify-content-center">
              <div className="col-12 col-xl-6 col-lg-7 col-md-9 col-sm-10 text-center last-paragraph-no-margin">
                <h5 className="alt-font font-weight-700 text-extra-dark-gray text-uppercase">ABOUT US</h5>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-xl-12 col-lg-12 col-md-9 col-sm-10 margin-eight-bottom md-margin-40px-bottom sm-margin-30px-bottom last-paragraph-no-margin">

                <p>The establishment of Salalah Petroleum Co. (SPCO) dates back to 2022. Founded in the Salalah Free Zone, Oman, SPCO began its journey with a total production capacity of 15,000 barrels per day.</p>

                <p>SPCO is driven by a team of highly skilled engineers and experts whose primary goal is to expand and enhance the company to meet global customer expectations. Over the years, SPCO has emerged as a prominent manufacturer in the region, specializing in a diverse range of oil and petroleum products, including white spirit, heavy and light hydrocarbons, jet fuel, industrial solvents, and more. Notably, SPCO possesses the capability to independently design, construct, and install refinery equipment.</p>

                <h6 className="font-weight-700 text-extra-dark-gray text-uppercase mt-5">Our Vision:</h6>

                <p>To become the leading company in refinery equipment design and engineering, while manufacturing a wide spectrum of oil and petroleum products using cutting-edge technology.</p>

                <h6 className="font-weight-700 text-extra-dark-gray text-uppercase mt-5">Our Mission:</h6>

                <p>To design, craft, and install refinery units and equipment, meeting the high demands of customers worldwide and enhancing the export process to the Middle East.</p>

                <h6 className="font-weight-700 text-extra-dark-gray text-uppercase mt-5">Our Core Values:</h6>

                <ol>
                  <li>Teamwork</li>
                  <li>Reliability</li>
                  <li>Integrity</li>
                  <li>Respect</li>
                  <li>Customer Focus</li>
                </ol>

                <h6 className="font-weight-700 text-extra-dark-gray text-uppercase mt-5">Chairman's Message</h6>

                <p>I wish to extend my deepest appreciation to our dedicated team for their unwavering commitment and hard work throughout the years. I firmly believe that there are no shortcuts to achieving our business goals while upholding essential values such as morals, ethics, and honesty.</p>

                <p>As we reflect on our journey, we can proudly acknowledge the significant milestones we have reached, which have propelled our company into a phase of rapid and sustained growth. Our primary focus remains on ensuring customer satisfaction and retention by delivering products of the highest quality at competitive prices.</p>

                <p>We eagerly anticipate the development of even stronger business relationships in the future. Thank you for your trust and support.</p>

                <p>
                  Sincerely,
                  Alireza Neghahban
                  Chairman, SPCO
                </p>

              </div>
            </div>

          </div>
        </section>

        <Footer />

      </Layout>

    )

  }

}